<template>
  <div :class="`absolute-center text-center`" style="top: 40%;">
      <div
          class="row items-center justify-center"
          style="height: 160px; width: 100%;"
      >
        <img
            :src="images[data.options?.image]"
            style="width: auto; height: 100%; object-fit: contain;"
        >
      </div>

      <p v-if="option" class="q-my-xs text-h6">
          {{ $t(data.option?.title) }}
      </p>

    <div
        style="min-width: 290px;"
        class="border rounded q-pa-sm q-my-sm"
    >
      <div class="text-center text-subtitle2 q-mb-sm">
        {{ data.options?.label }}
      </div>

      <div class="row items-center">
        <q-btn
            :label="data.value[0]?.label"
            color="teal"
            no-caps
            @click="click(data.value[0])"
        />

        <q-space/>

        <q-btn
            :label="data.value[1]?.label"
            color="warning"
            no-caps
            @click="click(data.value[1])"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuestionModal',
  emits: ['click'],
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    },
    rawData: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      isOpen: false,
      model: {},
      object: {
        data: []
      },
      images: {
        scanBasketPlace: 'assets/terminal-icons-md/basket.png',
        scanPlace: 'assets/terminal/terminal5-medium.png',
        scanOrder: 'assets/terminal/terminal5-medium.png',
        scanStaticPlace: 'assets/terminal-icons-md/IMG-0940.png',
        scanDistributionPlace: 'assets/terminal-icons-md/IMG-0949.png',
        scanSortingPlace: 'assets/terminal-icons-md/IMG-0944.png',
        finish: 'assets/terminal-icons-md/IMG-0951.png'
      }
    }
  },
  methods: {
    click (option) {
      this.$emit('click', {
        event: option.event,
        type: this.rawData.entityClass,
        data: {
          event: option.event,
          id: option.id,
        }
      })
    }
  }
}
</script>
